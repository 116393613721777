<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
              class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">Bank Kirim malumotlari</h3>
            </div>
          </div>

          <div class="card-body">
            <div>
              <v-tabs v-model="tab" class="wizard-step">
                <v-tab v-for="item in items" :key="item.tab">
                  {{ item.tab }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <!-- 1 -->
                <v-tab-item>
                  <ProductServices />
                </v-tab-item>
              </v-tabs-items>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ProductServices from '../DetailComponents/ProductServiceUpdate'
// import Factura from "./components/factura";
export default {
  name: 'ContractDetails',
  components: {
    ProductServices
  },
  data() {
    return {
      tab: 1,
      items: [
        {
          tab: 'Tovar va Hizmatlar'
        }
      ]
    }
  },
  beforeCreate() {},
  computed: {},
  methods: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Tovar Hizmatlari kirmi' },
      { title: 'Bank Kirim Hujjatlari' }
    ])
  }
}
</script>
